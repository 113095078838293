/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

import "core-js/stable";
import "regenerator-runtime/runtime";

const images = require.context("../stylesheets/images", true);
const imagePath = (name) => images(name, true);

import $ from "jquery";
import jQuery from "jquery";
window.$ = jQuery;
require("jquery-ujs");

import x2js from "x2js";
window.X2JS = x2js;

import moment from "moment";
window.moment = moment;

import { Calendar } from "@fullcalendar/core";
// import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
window.Calendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;

require("tablesorter");

// Tablesorter defaults
$.tablesorter.defaults.cssIcon = "fa";
$.tablesorter.defaults.cssIconAsc = "fa-sort-up";
$.tablesorter.defaults.cssIconDesc = "fa-sort-down";
$.tablesorter.defaults.cssIconNone = "fa-sort";
$.tablesorter.defaults.cssIconDisabled = "d-none";
$.tablesorter.defaults.theme = "bootstrap";
$.tablesorter.defaults.headerTemplate = "{content} {icon}";

import L from "leaflet";
import "libraries/leaflet.utm";
import "libraries/leaflet.contextmenu";
import "libraries/leaflet.markercluster";
import "libraries/leaflet.rotatemarker";
import "libraries/leaflet.autolayers";
import "libraries/leaflet.reachability";

import unidecode from "unidecode";
window.unidecode = unidecode;
import * as NatoConverter from "libraries/natoconverter";
window.NatoConverter = NatoConverter;

require("leaflet-measure");
import "stylesheets/leaflet-measure";
import "stylesheets/leaflet-autolayers";
import "stylesheets/leaflet-reachability";
import "leaflet-simplestyle/dist/leaflet-simplestyle";
// import "leaflet.locatecontrol/dist/L.Control.Locate";
require("leaflet.locatecontrol");

import wms from "leaflet.wms";
import easybutton from "leaflet-easybutton/src/easy-button";
import drawlocal from "leaflet-draw/dist/leaflet.draw";
window.L = L;
window.L.WMS = wms;
window.L.easybutton = easybutton;
window.L.drawlocal = drawlocal;
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import _ from "lodash";
window._ = _;

require("select2");
$.fn.select2.defaults.set("theme", "bootstrap4");

import { createConsumer } from "@rails/actioncable";
var App = {};
App.cable = createConsumer();
window.App = App;

import toastr from "toastr";
window.toastr = toastr;

import ColorScheme from "color-scheme";
window.ColorScheme = ColorScheme;

import chroma from "libraries/chroma";
window.chroma = chroma;

import Swal from "sweetalert2";
window.Swal = Swal;
window.swal = Swal;

import "bootstrap";
import "stylesheets/application.scss";

import "jsoneditor/dist/jsoneditor.min.css";
import JSONEditor from "jsoneditor/dist/jsoneditor.min.js";
window.JSONEditor = JSONEditor;

import "flag-icon-css/css/flag-icon.css";

import bsCustomFileInput from "bs-custom-file-input";

$(document).ready(function () {
  bsCustomFileInput.init();
});

import * as OperationJs from "custom/operations";
window.OperationJs = OperationJs;

import * as ApplicationJs from "custom/application";
window.ApplicationJs = ApplicationJs;

import * as TaskJs from "custom/tasks";
window.TaskJs = TaskJs;

import * as OperationLogJs from "custom/logs";
window.OperationLogJs = OperationLogJs;

import * as MapMethods from "custom/map";
window.MapMethods = MapMethods;

import * as GroupJs from "custom/groups";
window.GroupJs = GroupJs;

import * as CustomCsv from "custom/csv";
window.CustomCsv = CustomCsv;

import * as turf from "@turf/turf";
window.turf = turf;

import Chart from "chart.js";
window.Chart = Chart;

require("pc-bootstrap4-datetimepicker");
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

import "@SAReye-ehf/sareye-fontawesome-pro/css/all.css";
import "@SAReye-ehf/sareye-fontawesome-pro/js/all.js";
require("@rails/actiontext");
require("trix/dist/trix");

import Papa from "papaparse";
window.Papa = Papa;

import * as FileSaver from "file-saver";
window.FileSaver = FileSaver;

import "cocoon-js";
