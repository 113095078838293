function addMeasureButton(map) {
  let options = {
    position: "topleft",
    activeColor: "#ff5d30",
    completedColor: "#ff5d30",
    decPoint: ",",
    thousandsSep: ".",
    primaryLengthUnit: "meters",
    secondaryLengthUnit: "kilometers",
    primaryAreaUnit: "sqmeters",
    secondaryAreaUnit: "hectares",
  };
  var measureControl = new L.Control.Measure(options);
  measureControl.addTo(map);
}
function addLocateButton(map) {
  var lc = L.control
    .locate({
      position: "topright",
      strings: {
        title: "Display my location on the map.",
      },
      locateOptions: {
        enableHighAccuracy: true,
      },
    })
    .addTo(map);
  // lc.start();
}
function addReachabilityButton(map) {
  L.control.reachability({
    // add settings/options here
    rangeControlTime: [5, 10, 15, 30, 60, 120, 240, 480],
    drawButtonContent: '<i class="fa fa-fw fa-2x fa-plus"/>',
    deleteButtonContent: '<i class="fa fa-fw fa-2x fa-trash"/>',
    distanceButtonContent: '<i class="fa fa-fw fa-2x fa-road"/>',
    timeButtonContent: '<i class="fa fa-fw fa-2x fa-clock"/>',
    travelModeButton1Content: '<i class="fa fa-fw fa-2x fa-car"/>',
    travelModeButton2Content: '<i class="fa fa-fw fa-2x fa-bicycle"/>',
    travelModeButton3Content: '<i class="fas fa-fw fa-2x fa-walking"/>',
    travelModeButton4Content: '<i class="fa fa-fw fa-2x fa-wheelchair"/>',
    apiKey: '5b3ce3597851110001cf62485e388c8d9e62450c81f6911ca70c68ac'
  }).addTo(map);
}

function getLayerAltDescription(feature) {
  let keyFilterArray = [
    "marker-size",
    "marker-symbol",
    "marker-color",
    "stroke",
    "stroke-opacity",
    "stroke-width",
    "fill",
    "fill-opacity",
    "name",
    "description"
  ]
  if (feature.properties) {
    let returnValues = Object.keys(feature.properties).map(key => {
      if (feature.properties[key] && keyFilterArray.indexOf(key) === -1) {
        return `${key}: ${feature.properties[key]}`
      }
    })
    return returnValues.filter(a => a).join("<br>")
  }
}
function getLayerDescription(feature) {
  return feature.properties &&
    feature.properties.description &&
    typeof feature.properties.description !== "undefined"
    ? feature.properties.description
    : "";
}
function getLayerName(feature) {
  return feature.properties &&
    feature.properties.name &&
    typeof feature.properties.name !== "undefined"
    ? feature.properties.name
    : "";
}
export function getPopupText(layer) {
  let name = getLayerName(layer);
  let description = getLayerDescription(layer);
  let altDescription = getLayerAltDescription(layer);
  let string = ``;
  if (name) {
    string += `<b>${name}</b>`;
  }
  if (description) {
    string += `<br><p>${description}</p>`;
  }
  if (altDescription) {
    string += `<br><p>${altDescription}</p>`;
  }
  return string;
}

function addSearchInput(map) {
  // add a search function to the map
  L.easyButton("fa fa-search", function (btn, map) {
    $(".leaflet_search").toggleClass("d-none");
  }).addTo(map);
  var search = L.control({ position: "topleft" });
  search.onAdd = function (map) {
    var div = L.DomUtil.create("div", "info leaflet_search form-group d-none");
    div.innerHTML =
      '<input type="text" id="leaflet_search" class="form-control" placeholder="Address">';
    div.firstChild.onmousedown = div.firstChild.ondblclick =
      L.DomEvent.stopPropagation;
    return div;
  };
  search.addTo(map);

  $(document).on("touchend", "#leaflet_search", function (event) {
    // fixes event problem on mobile
    event.target.focus();
  });

  // init google geocoder
  var geocoder = new google.maps.Geocoder();
  function geocodeAddress(geocoder, resultsMap) {
    var address = $("#leaflet_search").val();
    geocoder.geocode(
      {
        address: address,
      },
      function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          //format our results to fit leaflet and center the map to them
          map.fitBounds([
            [
              results[0].geometry.viewport.getSouthWest().lat(),
              results[0].geometry.viewport.getSouthWest().lng(),
            ],
            [
              results[0].geometry.viewport.getNorthEast().lat(),
              results[0].geometry.viewport.getNorthEast().lng(),
            ],
          ]);
        }
      }
    );
  }
  //dont run the function if we are still typing
  var globalTimeout = null;
  $("#leaflet_search").keyup(function () {
    if (globalTimeout !== null) {
      clearTimeout(globalTimeout);
    }
    globalTimeout = setTimeout(function () {
      globalTimeout = null;
      // run our geocoding function when we finish typing
      geocodeAddress(geocoder, map);
    }, 200);
  });
}
function drawCameras(cameras) {
  fetch("/new_cameras.json")
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      var cameraIcon = {
        iconAnchor: [10, 20],
        iconSize: [20, 20],
        popupAnchor: [3, -10],
        iconUrl: "/icons/camera.svg",
      };
      var icon = new L.icon(cameraIcon);

      let cameraLocations = _.groupBy(data, function (camera) {
        return camera.maelistnr;
      });
      Object.keys(cameraLocations).map((id) => {
        let location = [cameraLocations[id][0].lat, cameraLocations[id][0].lng];
        let html = "";
        cameraLocations[id].map((camera) => {
          html += `<a target="_blank" href="${camera.url}">${camera.description}</a><img src="${camera.url}"/><br/>`;
        });
        var popuptext = cameraLocations[id][0].url;
        var marker = new L.marker(location, {
          icon: icon,
        }).on("click", function () {
          swal({
            width: 680,
            title: cameraLocations[id][0].camera,
            allowOutsideClick: true,
            html: html,
            showCloseButton: true,
          });
        });
        cameras.addLayer(marker);
      });
    })
    .catch(function (error) {
      console.error(error);
    });
}

function addFullscreenToggle(map) {
  var fullscreenToggle = L.easyButton({
    id: "fullscreen-marker-toggle",
    type: "animate",
    states: [
      {
        stateName: "expand",
        icon: "fa fa-expand",
        title: "Fullscreen",
        onClick: function (control) {
          var panel = $("#map-container");
          var button = $("#fullscreen-marker-toggle")
            .children()
            .children(".fa");
          button.toggleClass("fa-contract").toggleClass("fa-contract");
          panel.toggleClass("map_fullscreen");
          setTimeout(function () {
            window.dispatchEvent(new Event("resize"));
          }, 100);
        },
      },
      {
        stateName: "contract",
        icon: "fa fa-contract",
        title: "Fullscreen kort",
        onClick: function (control) {
          var panel = $("#map-container");
          var button = $("#fullscreen-marker-toggle")
            .children()
            .children(".fa");
          button.toggleClass("fa-contract").toggleClass("fa-contract");
          panel.toggleClass("map_fullscreen");
          setTimeout(function () {
            window.dispatchEvent(new Event("resize"));
          }, 100);
        },
      },
    ],
  });
  fullscreenToggle.addTo(map);
}

function showCoordinates(e) {
  let dms = `<b>DMS:</b><br>Lat: N ${parseReverseWSG84(
    e.latlng.lat
  )} Lng: W ${parseReverseWSG84(e.latlng.lng)}<br>`;
  let dd = `<b>DD:</b><br>Lat: ${e.latlng.lat} Lng: ${e.latlng.lng}<br>`;
  let utm = `<b>UTM:</b><br>${e.latlng.utm()}`;
  swal({
    title: "Coordinates",
    html: `${dms}<br>${dd}<br>${utm}`,
  });
}
function copyCoordsFromMap(e) {
  var coords_text = ApplicationJs.coordinatesText(
    e.latlng,
    _current_tenant
  ).replace(/(<([^>]+)>)/gi, "");

  var $temp = $("<input>");
  $("body").append($temp);
  $temp.val(coords_text).select();
  document.execCommand("copy");
  $temp.remove();

  toastr.success(`Copied - ${coords_text}`);
}
export function setupMap(div, options, extraContextMenuItems) {
  options = {
    ...options,
    contextmenu: true,
    contextmenuWidth: 140,
    contextmenuItems: [
      {
        text: "Show coordinates",
        callback: showCoordinates,
      },
      {
        text: "Copy coordinates",
        callback: copyCoordsFromMap,
      },
    ],
  };
  if (extraContextMenuItems) {
    options.contextmenuItems.push("-");
    options.contextmenuItems = options.contextmenuItems.concat(
      extraContextMenuItems
    );
  }
  return L.map(div, options);
}

export function setupLayerControls(map, operation_id) {
  // create the tile layer with correct attribution
  var osmUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  var osmAttrib =
    'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
  var osm = new L.TileLayer(osmUrl, {
    minZoom: 3,
    maxZoom: 20,
    attribution: osmAttrib,
  });

  var marineTrafficUrl =
    "https://tiles.marinetraffic.com/ais_helpers/shiptilesingle.aspx?output=png&sat=1&grouping=shiptype&tile_size={tileSize}&legends=1&zoom={z}&X={x}&Y={y}&test={avoidCache}";
  var marineTrafficAttrib =
    '© <a href="https://www.marinetraffic.com">Marine Traffic</a>';
  var marineTraffic = new L.TileLayer(marineTrafficUrl, {
    minZoom: 4,
    maxZoom: 20,
    zIndex: 400,
    attribution: marineTrafficAttrib,
    avoidCache: function () {
      return Math.random();
    },
  });

  var LMI = L.WMS.source("https://gis.lmi.is/geoserver/ows?", {
    transparent: true,
    format: "image/vnd.jpeg-png",
    version: "1.3.0",
    identify: false,
  });

  var SAMSYN = L.tileLayer.wms(
    "https://kort.samsyn.is/arcgis/services/Kort/SamsynLettkort/MapServer/WMSServer/?",
    {
      layers: "0",
      transparent: true,
      format: "image/png",
      detectRetina: true,
      version: "1.3.0",
    }
  );

  var sjokort = L.tileLayer.wms("https://gis.lmi.is/mapcache/sjokort?", {
    layers: "Sjomaelingar:Sjokort_Sjomaelinga",
    transparent: true,
    format: "image/vnd.jpeg-png",
    version: "1.3.0",
  });
  var ornefni = LMI.getLayer("Ornefni");
  var heightLines = LMI.getLayer("IS_50V:haedarlinur");
  var LMI_Dypislinur = LMI.getLayer("adrir_vektor:LHG_dypislinur");
  var LMI_Kort = LMI.getLayer("LMI_Kort");
  var LMI_Atlas = LMI.getLayer("LMI_raster:atlas");

  var baseLayers = {
    "<span class='base' overlay=false id='osm'>Open Street Map</span>": osm,
    "<span class='base' overlay=false id='sjokort'>Sjókort</span>": sjokort,
    "<span class='base' overlay=false id='samsyn'>Samsýn</span>": SAMSYN,
    // "<span class='base' overlay=false id='LMI_Kort'>LMI_Kort</span>": LMI_Kort,
    // "<span class='base' overlay=false id='LMI_Atlas'>LMI_Atlas</span>": LMI_Atlas
  };
  var oplogCircles = L.layerGroup();
  var cameras = L.layerGroup();

  var overlays = {
    "Marine Traffic": marineTraffic,
    // "Hitakort": temperatureLayer,
    Myndavélar: cameras,
    // "Örnefni": ornefni,
    // "Hæðarlínur": heightLines,
    // "Dýpislínur": LMI_Dypislinur
  };
  var map_image_overlay_url = "/map_image_overlays.json";
  if (operation_id) {
    map_image_overlay_url =
      "/operation/" + operation_id + "/map_image_overlays.json";
    overlays["Verkefni"] = taskMarkers;
    overlays["Atvik"] = oplogMarkers;
    overlays["Svæði"] = mapAreas;
    overlays["Area Names"] = mapAreaTooltips;
    overlays["Ferlar"] = mapPaths;
    overlays["Radíus Atvika"] = oplogCircleMarkers;
    if (_current_tenant.tenant_type.short_name === "sar") {
      overlays["IPP"] = ippCircles;
    }
  }
  $.ajax({
    // fetch the map tile servers
    url: map_image_overlay_url,
    method: "GET",
    async: false,
    cache: true,
    dataType: "json",
    success: function (data) {
      data.map((layer) => {
        var layerGroup = new L.layerGroup();
        var imageUrl = layer.include_image.path,
          imageBounds = [
            [layer.bounds_ne_lat, layer.bounds_ne_lng],
            [layer.bounds_sw_lat, layer.bounds_sw_lng],
          ];
        var imageOverlay = L.imageOverlay(imageUrl, imageBounds, {
          opacity: layer.opacity,
        });
        layerGroup.addLayer(imageOverlay);
        overlays[layer.name] = layerGroup;
        if (layer.show_on_load) {
          layerGroup.addTo(map);
        }
      });
    },
    error: function (e) {
      console.error(e);
    },
  });
  var preSelectedTileLayer = sessionStorage.getItem("selectedmap");
  var mapServers = [];

  $.ajax({
    // fetch the map tile servers
    url: "/map_tile_servers",
    method: "GET",
    async: false,
    cache: true,
    dataType: "json",
    success: function (data) {
      data.map((server) => {
        var object = {
          url: server.url,
          baseLayers: server.base_layers,
          whitelist: server.whitelist,
          blacklist: server.blacklist,
          name: server.name,
          tileUrl: server.tile_url,
          version: server.version,
          type: server.server_type,
          dictionary: server.dictionary,
        };
        if (object.blacklist.length === 0) {
          delete object.blacklist;
        }
        if (object.whitelist.length === 0) {
          delete object.whitelist;
        }
        if (object.baseLayers.length === 0) {
          delete object.baseLayers;
        }
        mapServers.push(object);
      });
    },
    error: function (e) {
      console.error(e);
    },
  });

  var config = {
    overlays: overlays, //custom overlays group that are static
    baseLayers: baseLayers, //custom baselayers group that are static
    selectedBasemap: preSelectedTileLayer || "osm", //selected basemap when it loads
    mapServers,
    selectedOverlays: [
      "Atvik",
      "Ferlar",
      "IPP",
      "Radíus Atvika",
      "Svæði",
      "Verkefni",
      "Area Names",
    ], //which overlays should be on by default
  };

  var layerControl = L.control.autolayers(config).addTo(map);
  $(".leaflet-control-layers").on(
    "click",
    ".leaflet-control-layers-selector",
    function () {
      var element = $(event.target).parents("label").find(".base");
      if (element.length !== 0) {
        sessionStorage.setItem("selectedmap", element.attr("id"));
      }
    }
  );
  $.ajax({
    // fetch the map tile layers and add them to the map
    url: "/map_tile_layers",
    method: "GET",
    cache: true,
    dataType: "json",
    success: function (data) {
      var tileLayerAdded = false;
      data.map((layer) => {
        var tileLayer = new L.TileLayer(layer.url, {
          tms: layer.tms,
          minZoom: layer.min_zoom,
          maxZoom: layer.max_zoom,
          attribution: layer.attribution,
        });
        var tileKey = `<span class='${
          layer.overlay ? "overlay" : "base"
        }'  id='${layer.id}'>${layer.name}</span>`;
        if (layer.id === preSelectedTileLayer) {
          map.addLayer(tileLayer);
          tileLayerAdded = true;
        }
        layer.overlay
          ? layerControl.addOverlay(tileLayer, tileKey)
          : layerControl.addBaseLayer(tileLayer, tileKey);
      });
      if (!tileLayerAdded) {
        map.addLayer(osm); // add the default map
      }
    },
    error: function () {
      map.addLayer(osm); // add the default map
    },
  });

  
  $.ajax({
    // fetch the map static objects and add them to the map
    url: `/map_static_objects?operation_id=${operation_id}`,
    method: "GET",
    cache: true,
    dataType: "json",
    success: function (data) {
      data.map((object) => {
        var options = { useSimpleStyle: true };
        options = {
          ...options,
          pointToLayer: function (feature, latlng) {
            var markerOptions = {
              className: object.id,
              iconAnchor: [10, 10],
              iconSize: [20, 20],
              popupAnchor: [3, -10],
            };
            markerOptions.html = `<i style="color: ${
              (feature.properties && feature.properties["marker-color"]) ||
              object.marker_color
            }" class='fa fa-lg fa-fw fa-${
              (feature.properties && feature.properties["marker-symbol"]) ||
              object.marker_icon
            }'/>`;
            var icon = new L.divIcon(markerOptions);

            var popupName = getLayerName(feature);
            var popupDescription = getLayerDescription(feature);
            return L.marker(latlng, { icon }).bindPopup(
              getPopupText(feature)
            );
          },
        };
        var geoJSON = L.geoJSON(JSON.parse(object.json), options);
        geoJSON.bindPopup(function (layer) {
          if (layer && layer.feature && layer.feature.properties) {
            let text = getPopupText(
              layer.feature
            );
            if (!text){
              return false
            }
            return text
            
          }
        });
        if (object.cluster_markers) {
          var markers = geoJSON;
          geoJSON = L.markerClusterGroup();
          geoJSON.addLayer(markers);
        }
        layerControl.addOverlay(geoJSON, object.name);
        if (object.show_on_load) {
          geoJSON.addTo(map);
        }
      });
    },
    error: function () {},
  });

  drawCameras(cameras);
  addFullscreenToggle(map);
  addSearchInput(map);
  addMeasureButton(map);
  addLocateButton(map);
  addReachabilityButton(map);
}
